angular
    .module('annexaApp')
    .component('annexaBoxThirds',{
        templateUrl: './components/thirds/annexa-box-thirds/annexa-box-thirds.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'RegFactory', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, RegFactory) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASThirds') {
                    _.forEach(args.selected, function (item) {
                        vm.search.searchedValue = { id: item.id, object: item };
                        vm.search.searchAdd();
                    });

                    args.modal.close();
                }
            });

            this.$onInit = function () {
            	
            	RegFactory.getOptionsRoleInterested();

                vm.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.receivers';
                }

                if(!vm.search) {
                    vm.search = {};
                }

                vm.search.addedSelect = 'x => x.third.id'
                vm.search.origin = 'thirds';
                vm.search.type = 'Third';
                vm.search.processSearch = function(object) {
                    if(object.corporateName) {
                        return object.corporateName + ' (' + object.identificationDocument + ')';
                    } else {
                        return object.name + ' ' + object.surename1 + (object.surename2 ? ' ' + object.surename2 : '') + ' (' + object.identificationDocument + ')';
                    }
                }
                if(!vm.search.annexaFormModalOnAdd) {
                    var getRepresentateds = function() {
                        var retRepresentateds = [];

                        var representeds = $linq(vm.content).where("x => x.representated != null").select("x => x.representated").toArray();

                        var interested = $linq(vm.content).where("x => x.interested").except(representeds,"(x,y) => x.id == y.third.id").toArray();

                        _.forEach(interested, function (value) {
                            var representated = value.third;
                            if(value.third.name) {
                                representated.label = value.third.name + ' ' + value.third.surename1 + ((value.third.surename2)? ' ' + value.third.surename2 : '' ) + ' (' + value.third.identificationDocument + ')';
                            }else{
                                representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                            }
                            retRepresentateds.push(representated);
                        });

                        return retRepresentateds
                    }
                    
                    var addressesArray = function() {
                        var content = [];

                        if(vm && 
                        		vm.search && 
                        		vm.search.searchedValue && 
                        		vm.search.searchedValue.object &&
                        		vm.search.searchedValue.object.addresses) {
                        	var lstAddresses = vm.search.searchedValue.object.addresses;
                        	var thirdAddress = new ThirdAddress(vm.search.searchedValue.object, Language.getActiveColumn());
                        	
                        	if (lstAddresses && lstAddresses.length > 0) {                        		
                            	_.forEach(lstAddresses, function (value) {
                            		var thirdAddressId = value.id;                            		
                            		var addressHtml = thirdAddress.getAddressHtml(value, false, true);
                            		var address = {value: thirdAddressId, label: addressHtml};  
                            		content.push(address);
                            	})                            	                        		
                        	}
                        	
                        }

                        return content;
                    }

                    vm.search.annexaFormModalOnAdd = {
                        title: 'global.literals.receiver',
                        size: '',
                        fieldsDefinition: [
                        	{ type: 'field', id: 'thirdType', fieldType: 'radio_checkbox', isRadio: true, 
                        		data: [{value: 'interested', label: $filter('translate')('global.literals.interested')},
                        			   {value: 'representative', label: $filter('translate')('global.literals.representative')}],
                        		defaultValue: 'interested',	   
                        		colClass: 'col-sm-12', required: true, label: 'global.literals.type' , labelProp: 'label'},
                        	{ type: 'field', id: 'roleInterested', fieldType: 'select', data: [], required: false, label: 'global.literals.roleInterested', labelProp: 'label',
                        		hideExpression: function($viewValue, $modelValue, scope) {
                        			var hide = true;
                                	if (scope.model.thirdType == 'interested' && RegFactory.optionsRoleInterested) {
                                		hide = false;
                                	}
                                	return hide;
                        		},	
                        		controller: ['$scope', function($scope) { 
                        			$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.options = RegFactory.optionsRoleInterested;
                                }]
                        	},	
                            { type: 'field', id: 'representated', fieldType: 'select', data: getRepresentateds(), required: true, label: 'global.literals.representated', labelProp: 'label',
                                hideExpression: function($viewValue, $modelValue, scope) { return scope.model.thirdType != 'representative'; },
                                controller: ['$scope', function($scope) { 
                                	$scope.to.options = getRepresentateds() 
                                }]
                            },
                            { type: 'field', id: 'thirdAddress', fieldType: 'radio_checkbox', isRadio: true, data: addressesArray(), colClass: 'col-sm-12', required: false, label: 'global.literals.notificationChannel' , labelProp: 'label',
                                controller: ['$scope', function($scope) { 
                                	$scope.to.options = addressesArray() 
                                }]},
                        ],
                        alerts: []
                    }
                }

                if(!vm.search.modalOnAddProcessModel) {
                    vm.search.modalOnAddProcessModel = function(selected) {
                        return { third: selected.id, interested: false, representative: false, object: selected.object };
                    }
                }
                if(!vm.search.completeAdd) {
                    vm.search.completeAdd = function() {
                    	//Carga los datos en html de la direccion seleccionada                    	
                    	var addressHtml;
                    	var thirdAddressId;
                    	if (this.model && this.model.thirdAddress) {
                    		thirdAddressId = this.model.thirdAddress
	                    	var lstAddresses = vm.search.searchedValue.object.addresses;
	                    	var thirdAllAddress = new ThirdAddress(vm.search.searchedValue.object, Language.getActiveColumn());	                    	
	                    	if (lstAddresses && thirdAddressId) {            
	                    		var value = $linq(lstAddresses).where("x => x.id == '"+ thirdAddressId +"'").toArray();
	                    		addressHtml = thirdAllAddress.getAddressHtml(value[0], true, false);
	                    	}
                    	}
                    	
                        var newContent = {
                            third: vm.search.searchedValue.object,
                            interested: this.model.thirdType == 'interested' ? true : false,
                            roleInterested: this.model.roleInterested ? $linq(RegFactory.optionsRoleInterested).where("x => x.id == " + this.model.roleInterested).toArray()[0] : undefined,		
                            representative: this.model.thirdType == 'representative' ? true : false,
                            principal: vm.content.length == 0 ? true : false,
                            addresses: new BoxThird(vm.search.searchedValue.object, Language.getActiveColumn()).getAdressesHtml(),                            
                            representated: this.model.representated ? $linq(vm.content).where("x => x.third && x.third.id == '"+ this.model.representated +"'").toArray()[0].third : undefined,                        
                            thirdAddress: thirdAddressId,
                            thirdAddressHtml: addressHtml
                        }

                        if (this.model.thirdType == 'interested' || (this.model.thirdType == 'representative' && this.model.representated) ) {	                        
	
	                        vm.search.searchedValue = { id: -1, value: '' };
	                        if(vm.search.saveSearch) {
	                            vm.search.saveSearch(newContent);
	                        } else {
	                        	vm.content.push(newContent);
	                        }
                        
                        	$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
                        }
                    }
                }

                vm.search.advancedSearch = function() {
                    var renderThirdType = function(data, type, full, meta) {
                        var content = '';
                        var icon = '';

                        switch (data) {
                            case 'PHISICAL':
                                icon = 'fa-user fa-lg ico-third';
                                break;
                            case 'LEGAL':
                                icon = 'fa-bank fa-lg ico-third';
                                break;
                            case 'PERSONWITHOUTLEGALPERSONALITY':
                                icon = 'fa-puzzle-piece fa-lg ico-third';
                                break;
                        }

                        content = '<i class="fa ' + icon + '"></i>';

                        return content;
                    }

                    var renderAddresses = function(data, type, full, meta) {
                        var content = '';

                        if(full) {
                            content = new ThirdAddress(full, Language.getActiveColumn()).getDefaultAddressHtml();
                        }

                        return content;
                    }

                    var advancedSearchModal = {
                        title: 'global.literals.advanced_search_receivers',
                        size: 'modal-lg',
                        filter: [
                            { id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier' },
                            { id: 'name', type: 'text', order: 1, label: 'global.literals.name' },
                            { id: 'city', type: 'text', order: 0, label: 'global.literals.city', userChoosen: true, callAux: true },
                            { id: 'mail', type: 'text', order: 1, label: 'global.profile.email', userChoosen: true, callAux: true },
                            { id: 'phone', type: 'text', order: 2, label: 'global.profile.phone', userChoosen: true, callAux: true },
                            { id: 'identificationDocumentType', type: 'select', order: 3, label: 'global.thirds.literals.identificationdocumenttype', dataType: 'LOCAL', data: GlobalDataFactory.thirdIdentificationDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true, callAux: true },
                            { id: 'thirdType', type: 'select', order: 4, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.thirdTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true, callAux: true },
                            { id: 'dataThird', type: 'dateRange', order: 5, label: 'global.thirds.literals.dateCreated', userChoosen: true, callAux: true }
                        ],
                        tableDefinition: {
                            id: 'tableAdvancedSearchThirds',
                            origin: 'thirds',
                            objectType: 'Third',
                            sortName: 'identificationDocument',
                            sort: [[2, 'asc']],
                            columns: [
                                { id: 'thirdType', title: $filter('translate')('global.literals.type'), render: renderThirdType, sortable: false },
                                { id: 'identificationDocument', title: $filter('translate')('global.literals.identifier') },
                                { id: 'id', width: '25%', column: new ThirdColumn($filter, 'global.thirds.literals.datatable.col2', true) },
                                { id: 'addresses', title: $filter('translate')('global.thirds.literals.datatable.col4'), render: renderAddresses, sortable: false }
                            ],
                            noLoading: true,
                            callAuxToAdd: { added: $linq(vm.content).select("x => x.third.id").toArray() }
                        },
                        multiple: false
                    }

                    AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASThirds', advancedSearchModal);
                }


                if(!vm.new) {
                    vm.new = {};
                }

                vm.new.newFunc = function() {
                    var modal = globalModals.receiverNew;

                    if(vm.entryType == 'INPUT') {
                        modal.title = 'global.literals.newRemitent';
                    } else if(vm.entryType == 'OUTPUT') {
                        modal.title = 'global.literals.new_receiver_modal_title';
                    }

                    //region Això s'haurà de canviar quan arreglem tercers

                    var proccesNew = function(item, form) {
                        vm.search.searchedValue = { id: item.third.id, object: item.third }
                        $rootScope.$broadcast('annexaObjectBoxSearchAdd');
                        form.close();
                    }

                    var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getThirdBoxNewForm(modal, proccesNew), AnnexaEntityFactory.getThirdBoxes());

                    modal.annexaFormly = entity.form;
                    modal.boxes = entity.boxes;
                    modal.submitModal = modal.annexaFormly.onSubmit;
                    modal.alerts = [];
                    entity.modal = modal;

                    ModalService.showModal({
                        template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                        controller: 'AnnexaFormlyModalController',
                        modalObj: entity
                    }).then(function(modalThen) {
                        modal.modal = modalThen;
                        modal.close = modalThen.element.scope().close;
                        modal.annexaFormly.close = modalThen.element.scope().close;
                        modalThen.element.modal();
                        angular.element(".modal-title").focus();
                        /*if(pluginId && pluginObject){
                            AnnexaPlugins.addPlugin(modalThen.element.scope(), pluginObject, pluginId);
                        }*/
                    });

                    //endregion

                }

                if(vm.isEdit) {
                    _.forEach(vm.content, function(item, index) {
                    	if (item.thirdAddress) {
                    		var thirdAllAddress = new ThirdAddress(item.third, Language.getActiveColumn());
                    		vm.content[index].thirdAddressHtml = thirdAllAddress.getAddressHtml(item.thirdAddress, true, false);
                    	}
                        if(item.third.addresses) {
                            vm.content[index].addresses = new BoxThird(item.third, Language.getActiveColumn()).getAdressesHtml()
                        }
                    })
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxThirdsRender', {
        templateUrl: './components/thirds/annexa-box-thirds/annexa-box-thirds-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['RegFactory', '$filter', 'DialogsFactory', '$rootScope', '$q', '$scope', 'AnnexaFormlyFactory', 'globalModals', 'Language', '$http', 'ErrorFactory', function(RegFactory, $filter, DialogsFactory, $rootScope, $q, $scope, AnnexaFormlyFactory, globalModals, Language, $http, ErrorFactory) {
            var vm = this;
            
            vm.languageColumn = Language.getActiveColumn();

            vm.setPrincipal = function (index) {
                if(vm.content.length > 0) {
                    _.forEach(vm.content, function(item, cont) {
                        vm.content[cont].principal = false;
                    });

                    vm.content[index].principal = true;

                    if(vm.isEdit) {
                        $rootScope.$broadcast('annexaBoxThirdsSetPrincipal', { id: vm.content[index].id, origin: vm.origin});
                    }
                }
            }

            vm.getShowName = function (third) {
                if(third.thirdType != 'PHISICAL') {
                    return third.corporateName;
                } else {
                    return third.name + ' ' + third.surename1 + (third.surename2 ? ' ' + third.surename2 : '');
                }
            }

            vm.getRepresentativeText = function(item) {
                if(item.representative && item.representated) {
                    var representated = undefined;

                    if(item.representated.hasOwnProperty('id')) {
                        representated = $linq(vm.content).singleOrDefault(undefined, "x => x.third.id == " + item.representated.id);
                    } else {
                        representated = $linq(vm.content).singleOrDefault(undefined, "x => x.third.id == " + item.representated);
                    }

                    return $filter('translate')('global.literals.representative',{ representated: vm.getShowName(representated.third) + ' (' + representated.third.identificationDocument + ')' });
                } else {
                    return '';
                }
            }

            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteThirdBox')
                    .then(function (data) {
                        var removedIds = [];

                        var removed = angular.copy(vm.content[index]);
                        vm.content.splice(index, 1);
                        removedIds.push(removed.id);

                        if(removed.interested){
                            var indexRepresentative = $linq(vm.content).indexOf("x => x.representative && x.representated && (x.representated == " + removed.third.id + " || x.representated.id == " + removed.third.id + ")");

                            if(indexRepresentative != -1) {
                                removedIds.push(vm.content[indexRepresentative].id);
                                vm.content.splice(indexRepresentative, 1);
                            }
                        }

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxThirdsDelete', { removedIds: removedIds, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }

            vm.showAddresses = function() {
                return vm.origin != 'annexa-box-object-reg' || (vm.origin == 'annexa-box-object-reg' && vm.entryType != 'INPUT')
            }
            
            vm.editThirdReceiver = function(index) {  
            	var receiver = angular.copy(vm.content[index]);
            	$scope.receiver = receiver;
            	$scope.index = index;            	
            	
            	if (receiver.interested) {
            		var receiverThirdType = 'interested';
	        	} else if (receiver.representative) {
	        		var receiverThirdType = 'representative';	        		
	        	}
            	if (receiver.representated) {
            		if (typeof receiver.representated == 'number') {
            			var idReceiverRepresentated = receiver.representated;
            		} else {
            			var idReceiverRepresentated = receiver.representated.id;
            		}
            	}
            	if (receiver.thirdAddress) {
            		if (typeof receiver.thirdAddress == 'number') {
            			var idReceiverThirdAddress = receiver.thirdAddress;
            		} else {
            			var idReceiverThirdAddress = receiver.thirdAddress.id;
            		}
            	}
            	
            	var getRepresentateds = function(receiver) {
                    var retRepresentateds = [];

                    if (idReceiverRepresentated) {
                    	var representeds = $linq(vm.content).where("x => x.representated != null && ((x.representated.id != " + idReceiverRepresentated + ") && (x.representated != " + idReceiverRepresentated + "))").select("x => x.representated").toArray();
                    } else {
                    	var representeds = $linq(vm.content).where("x => x.representated != null").select("x => x.representated").toArray();
                    }

                    if (receiver.id) {
                    	var interested = $linq(vm.content).where("x => x.interested && x.id != " + receiver.id).except(representeds,"(x,y) => x.id == y.third.id").toArray();
                    } else {
                    	var interested = $linq(vm.content).where("x => x.interested && x.third.id != " + receiver.third.id).except(representeds,"(x,y) => (x.id && (x.id == y.third.id)) || (x == y.third.id)").toArray();
                    }
                    
                    _.forEach(interested, function (value) {
                        var representated = value.third;
                        if(value.third.name) {
                            representated.label = value.third.name + ' ' + value.third.surename1 + ((value.third.surename2)? ' ' + value.third.surename2 : '' ) + ' (' + value.third.identificationDocument + ')';
                        }else{
                            representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                        }
                        retRepresentateds.push(representated);
                    });

                    return retRepresentateds
                }
            	
            	var addressesArray = function(receiver) {
                    var content = [];

                    if(receiver) {
                    	var lstAddresses = receiver.third.addresses;
                    	var thirdAddress = new ThirdAddress(receiver, Language.getActiveColumn());
                    	
                    	if (lstAddresses && lstAddresses.length > 0) {                        		
                        	_.forEach(lstAddresses, function (value) {
                        		var thirdAddressId = value.id;                            		
                        		var addressHtml = thirdAddress.getAddressHtml(value, false, true);
                        		var address = {value: thirdAddressId, label: addressHtml};  
                        		content.push(address);
                        	})                            	                        		
                    	}
                    	
                    }

                    return content;
                }
            	
            	var getIdReceiverOptionRoleInterested = function(receiver) {
            		if (receiver.roleInterested) {
            			return receiver.roleInterested.id;
            		}
            		return undefined;
            	}
            	
            	var modalEditReceiver = {
                    title: 'global.literals.edit_receiver',
                    size: '',
                    fieldsDefinition: [
                    	{ type: 'field', id: 'thirdType', fieldType: 'radio_checkbox', isRadio: true, 
                    		data: [{value: 'interested', label: $filter('translate')('global.literals.interested')},
                    			   {value: 'representative', label: $filter('translate')('global.literals.representative')}],
                    		defaultValue: receiverThirdType,	   
                    		colClass: 'col-sm-12', required: true, label: 'global.literals.type' , labelProp: 'label'},
                		{ type: 'field', id: 'roleInterested', fieldType: 'select', data: RegFactory.optionsRoleInterested, required: false, label: 'global.literals.roleInterested', labelProp: 'label',
                    		defaultValue: getIdReceiverOptionRoleInterested(receiver),
                    		hideExpression: function($viewValue, $modelValue, scope) {
                    			var hide = true;
                            	if (scope.model.thirdType == 'interested' && RegFactory.optionsRoleInterested) {
                            		hide = false;
                            	}
                            	return hide;
                    		},	
                    		controller: ['$scope', function($scope) { 
                    			$scope.to.labelProp = Language.getActiveColumn();
                            	$scope.to.options = RegFactory.optionsRoleInterested;
                            }]
                    	},	
                        { type: 'field', id: 'representated', fieldType: 'select', data: getRepresentateds(receiver), required: true, label: 'global.literals.representated', labelProp: 'label',
                    		defaultValue: idReceiverRepresentated,	
                            hideExpression: function($viewValue, $modelValue, scope) { return scope.model.thirdType != 'representative'; },
                            controller: ['$scope', function($scope) { 
                            	$scope.to.options = getRepresentateds(receiver) 
                            }]
                        },
                        { type: 'field', id: 'thirdAddress', fieldType: 'radio_checkbox', isRadio: true, data: addressesArray(receiver), colClass: 'col-sm-12', required: false, label: 'global.literals.notificationChannel' , labelProp: 'label',
                        	defaultValue: idReceiverThirdAddress,
                            controller: ['$scope', function($scope) { 
                            	$scope.to.options = addressesArray(receiver)
                            }]},
                    ],
                    alerts: []
                }     
            	
            	var submitFunction = function() {
                	var receiver = $scope.receiver; 
                	var thirdType = this.model.thirdType;
                	var idRepresentated = this.model.representated;
                	var idThirdAddress = this.model.thirdAddress;
                	var idRoleInterested = this.model.roleInterested;
                	
                	if (receiver.id) {
                		//Se guarda en BBDD
	                	return $http({
	                        url: './api/register_entry_third/' + receiver.id,
	                        method: 'GET'
	                    }).then(function (data) {                                                
	                        var registerEntryThird = JSOG.decode(data.data);
	                        
	                        if (thirdType == 'interested') {
	                        	registerEntryThird.interested = true;
	                        	registerEntryThird.representated = undefined;
	                        	if (registerEntryThird.roleInterested) {
	                        		registerEntryThird.roleInterested.id = idRoleInterested ? idRoleInterested : undefined;
	                        	} else {
	                        		registerEntryThird.roleInterested = idRoleInterested ? {id: idRoleInterested} : undefined;
	                        	}
	                        } else {
	                        	registerEntryThird.interested = false;
	                        	registerEntryThird.roleInterested = undefined;
	                        }
	                        
	                        if (thirdType == 'representative') {
	                        	registerEntryThird.representative = true;                        	
	                        	if (registerEntryThird.representated) {
	                            	registerEntryThird.representated.id = idRepresentated ? idRepresentated : undefined;
	                            } else {
	                            	registerEntryThird.representated = idRepresentated ? {id: idRepresentated} : undefined;
	                            }
	                        } else {
	                        	registerEntryThird.representative = false;
	                        }  
	                        
	                        if (idThirdAddress) {
	                        	if (!registerEntryThird.thirdAddress || (registerEntryThird.thirdAddress && registerEntryThird.thirdAddress.id != idThirdAddress)) {
	                        		var thirdAddress = $linq(registerEntryThird.third.addresses).where("x => x.id == '"+ idThirdAddress +"'").toArray();
	                        		registerEntryThird.thirdAddress = thirdAddress[0];
	                        	}
	                        } else {
	                        	registerEntryThird.thirdAddress = undefined;
	                        }
	                                                
	                        RegFactory.updateRegisterEntryThird(receiver.id, registerEntryThird)
	                            .then(function(data) {
	                            	if (data.roleInterested) {
	                            		data.roleInterested = $linq(RegFactory.optionsRoleInterested).where("x => x.id == " + data.roleInterested.id).toArray()[0] 
	                            	}
	                            	
	                            	if (data.thirdAddress) {
	                            		var thirdAllAddress = new ThirdAddress(data.third, Language.getActiveColumn());
	                            		var addressHtml = thirdAllAddress.getAddressHtml(data.thirdAddress, true, false);
	                            	}
	                            	
	                            	var receiver = data;
	                            	if (addressHtml) {
	                            		receiver.thirdAddressHtml = addressHtml;
	                            	}
	                            	data.addresses = new BoxThird(data.third, Language.getActiveColumn()).getAdressesHtml();
	                            	
	                            	vm.content[$scope.index] = data;
	                            	
	                            	$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
	                            }).catch(function (error) {
	                                console.error(error);
	                                modalEditReceiver.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
	                        });                    	                    	                        
	                        
	                    }).catch(function (error) {
	                        console.error(error);
	                        modalEditReceiver.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
	                    });   
                	} else {
                		//No se guarda en BBDD
                		if (thirdType == 'interested') {
                			receiver.interested = true;
                			receiver.representated = undefined;
                			receiver.roleInterested = idRoleInterested ? $linq(RegFactory.optionsRoleInterested).where("x => x.id == " + idRoleInterested).toArray()[0] : undefined;
                        } else {
                        	receiver.interested = false;
                        	receiver.roleInterested = undefined;
                        }
                        
                        if (thirdType == 'representative') {
                        	receiver.representative = true;                        	
                        	receiver.representated = idRepresentated ? idRepresentated : undefined;
                        } else {
                        	receiver.representative = false;
                        }  
                        
                        if (idThirdAddress) {
                        	if (!receiver.thirdAddress || (receiver.thirdAddress && receiver.thirdAddress.id != idThirdAddress)) {
                        		var thirdAddress = $linq(receiver.third.addresses).where("x => x.id == '"+ idThirdAddress +"'").toArray();
                        		receiver.thirdAddress = thirdAddress[0];
                        	}
                        } else {
                        	receiver.thirdAddress = undefined;
                        }
                        
                        if (receiver.thirdAddress) {
                    		var thirdAllAddress = new ThirdAddress(receiver.third, Language.getActiveColumn());
                    		var addressHtml = thirdAllAddress.getAddressHtml(receiver.thirdAddress, true, false);
                    	}
                    	
                    	if (addressHtml) {
                    		receiver.thirdAddressHtml = addressHtml;
                    		receiver.thirdAddress = receiver.thirdAddress.id;
                    	}
                    	receiver.addresses = new BoxThird(receiver.third, Language.getActiveColumn()).getAdressesHtml();
                        
                        vm.content[$scope.index] = receiver;
                    	
                    	$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });                        
                	}
                }
                        	
            	AnnexaFormlyFactory.showAnnexaFormModal('modalSearchAdd', modalEditReceiver, submitFunction);            	
            }
            
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })